<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">会议房间列表</p>
            <p class="btn-wrap-width" @click="editRoom(null)">新增房间</p>
        </div>

        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">房间名称</div>
                <div class="table-location">房间位置</div>
                <div class="table-size">房间大小</div>
                <div class="table-arrange">房间排列</div>
                <div class="table-operation">操作</div>
            </div>

            <div v-for="(room, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{  (index+1)}}</div>
                <div class="table-name" style="font-weight: bold;">{{  room.name}}</div>
                <div class="table-location">{{  room.location}}</div>
                <div class="table-size">{{  room.room_size}}</div>
                <div class="table-arrange">{{ room.arrangement }}</div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;" @click="editRoom(room)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveRoom(room)">删除</p>
                </div>
            </div>

        </div>

        <el-dialog :visible.sync="showRoomDialog" :title="roomInfo.id == '' ? '添加房间' : '编辑房间'">
            <div>
                <el-form :model="roomInfo" :rules="rules" ref="roomForm">
                    <el-form-item label="房间名称" prop="name">
                        <el-input v-model="roomInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="房间位置" prop="location">
                        <el-input v-model="roomInfo.location" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="房间大小" prop="room_size">
                        <el-input v-model="roomInfo.room_size" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="房间排列">
                        <el-select v-model="roomInfo.arrangement" placeholder="请选择">
                            <el-option label="剧院式" value="剧院式"></el-option>
                            <el-option label="课桌式" value="课桌式"></el-option>
                            <el-option label="圆桌式" value="圆桌式"></el-option>
                            <el-option label="吧台式" value="吧台式"></el-option>
                        </el-select>
                        <el-input v-model="custom_arrangement" placeholder="自定义房间排列"
                            style="width: 300px;margin-left: 15px;"></el-input> (自定义)
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showRoomDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveRoom()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import '../../assets/common/common.css'
import {
    getMeetingRooms,
    removeMeetingRoom,
    updateMeetingRoom,
} from '../../api/api'

export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            roomInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                location: '',
                room_size: '',
                arrangement: ''
            },
            custom_arrangement: '',
            rules: {
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                location: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                room_size: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ]
            },
            showRoomDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getMeetingRooms(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveRoom(row) {
            this.$alert('你确定要删除此会议房间吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeMeetingRoom(row._id.$id).then((res) => {
                            this.fetchData()
                            this.$message.success('删除成功~')
                        })
                    }
                }
            })
        },
        editRoom(row) {
            this.custom_arrangement = ''
            if (row) {
                this.roomInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    name: row.name,
                    location: row.location,
                    room_size: row.room_size,
                    arrangement: row.arrangement
                }
            } else {
                this.roomInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    name: '',
                    location: '',
                    room_size: '',
                    arrangement: ''
                }
            }
            this.showRoomDialog = true
        },
        saveRoom() {
            this.$refs.roomForm.validate((val) => {
                if (val) {
                    if (!this.roomInfo.arrangement) {
                        this.roomInfo.arrangement = this.custom_arrangement
                    }
                    updateMeetingRoom(this.roomInfo).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showRoomDialog = false
                    })
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-name {
    flex: 2;
}

.table-location {
    flex: 2;
}

.table-size {
    flex: 2;
}

.table-arrange {
    flex: 2;
}

.table-operation {
    flex: 1.5;
}
</style>